import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import { TableCell, TableRow } from '@mui/material';
import { Column, IBusinessRequests } from '../types';
import styles from '../styles.module.css';

type Props = {
  columns: Column[];
  row: IBusinessRequests;
  onPageDelete: (mailId: string) => void;
  onPreview: () => void;
};

export const TableRowComponent = ({ row, columns, onPageDelete, onPreview }: Props) => {
  const handleColumnValue = (column: Column) => {
    switch (column.name) {
      case 'actions':
        return;
      default:
        return row[column.name];
    }
  };
  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      {columns.map((column) => {
        const value = handleColumnValue(column);
        if (column.name === 'actions') {
          return (
            <TableCell key={column.name} align={column.align}>
              <div className={styles.action}>
                <PreviewIcon onClick={onPreview} />
                <DeleteIcon
                  color="error"
                  onClick={() => onPageDelete(row.id)}
                />
              </div>
            </TableCell>
          );
        }

        return (
          <TableCell key={column.name} align={column.align}>
            {value as string}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
