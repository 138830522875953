export const isObjectEmpty = (object: object | null) => {
  return object ? Object.keys(object).length === 0 : true;
};

export const isValidJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const parseJsonStringsInObject = (obj: any): any => {
  const isJsonString = (str: string) => {
    return (
      (str.startsWith('{') && str.endsWith('}')) ||
      (str.startsWith('[') && str.endsWith(']'))
    );
  };

  // Recursively traverse the object
  function traverseAndParse(value: any): any {
    if (typeof value === 'string' && isJsonString(value)) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    } else if (Array.isArray(value)) {
      return value.map(traverseAndParse);
    } else if (value !== null && typeof value === 'object') {
      const parsedObject: any = {};
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          parsedObject[key] = traverseAndParse(value[key]);
        }
      }
      return parsedObject;
    } else {
      return value;
    }
  }

  return traverseAndParse(obj);
};
