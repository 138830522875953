import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../components/Breadcrumbs/Breadcrumbs';
import CustomTablePagination from '../../components/CustomTablePagination/CustomTablePagination';
import { DeleteMailModal } from './components/DeleteMailModal';
import { TableRowComponent } from './components/TableRowComponent';
import { Column } from './MailsPage.type';
import styles from './styles.module.css';
import { useMailsPage } from './useMailsPage';

const columns: Column[] = [
  { name: 'email', label: 'Email', minWidth: 170 },
  { name: 'phoneNumber', label: 'Phone number', minWidth: 100 },
  {
    name: 'source',
    label: 'Source',
    minWidth: 170,
  },
  {
    name: 'communicationChannel',
    label: 'Communication channel',
    minWidth: 100,
  },
  {
    name: 'service',
    label: 'Service',
    minWidth: 170,
  },
  {
    name: 'createdAt',
    label: 'Created at',
    minWidth: 170,
  },
  {
    name: 'actions',
    label: 'Actions',
    minWidth: 100,
  },
];

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Choice',
    to: '/choice',
    icon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Mails',
    to: '',
    icon: <EmailIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

export const MailsPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const {
    mails,
    metaData,
    page,
    quantity,
    order,
    setPage,
    setQuantity,
    setOrder,
    updateMailsAfterDelete,
  } = useMailsPage();

  const onPageDelete = (mailId: string) => {
    setOpenModal(true);
    setDeleteId(mailId);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setQuantity(+event.target.value);
    setPage(1);
  };

  return (
    <Container maxWidth="xl">
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h2">Mails</Typography>
        </div>
        <BreadcrumbsComponent linkList={linkList} />
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.name}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: 'bolder',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {mails.map((row, index) => (
                  <TableRowComponent
                    row={row}
                    columns={columns}
                    onPageDelete={onPageDelete}
                    key={index}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {metaData && (
            <CustomTablePagination
              count={metaData.pageCount}
              rowsPerPage={quantity}
              page={page - 1}
              onChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </div>
      <DeleteMailModal
        open={openModal}
        onClose={onCloseModal}
        mailId={deleteId}
        updateMailsAfterDelete={updateMailsAfterDelete}
      />
    </Container>
  );
};
