import { useState } from 'react';
import { API } from '../../../../api/api';
import { URLS } from '../../../../api/urls';
import { Modal } from '../../../../components/Modal/Modal';
import { ALERT_TEXT } from '../../../../constants/content';
import { useAlert } from '../../../../context/Alert.context';
import { ModelDto } from '../../../../types/models.types';
import { ITradeIn } from '../../../../types/tradeIn.types';
import { IEditTradeIn } from '../../TradeInPage.type';
import { ModelForm } from './ModelForm';

interface EditModelModalProps {
  open: boolean;
  onClose: () => void;
  updateModelAfterEditTradeIn: (tradeIn: ITradeIn, model: ModelDto) => void;
  selectedModel: ModelDto;
}

export const EditModelModal = ({
  open,
  onClose,
  updateModelAfterEditTradeIn,
  selectedModel,
}: EditModelModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const editTradeIn = (tradeIn: IEditTradeIn) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('maxPrice', String(tradeIn.maxPrice));
    formData.append('minPrice', String(tradeIn.minPrice));
    formData.append('hardDisk', String(tradeIn.hardDisk));
    formData.append('memory', String(tradeIn.memory));
    formData.append('memoryPercents', String(tradeIn.memoryPercents));
    formData.append('isWorkFine', String(tradeIn.isWorkFine));
    formData.append('isWorkFinePercents', String(tradeIn.isWorkFinePercents));
    formData.append('isScreenFine', String(tradeIn.isScreenFine));
    formData.append(
      'isScreenFinePercents',
      String(tradeIn.isScreenFinePercents),
    );
    formData.append('screenFrameState', String(tradeIn.screenFrameState));
    formData.append(
      'screenFrameStatePercents',
      String(tradeIn.screenFrameStatePercents),
    );
    formData.append('isGlassSurface', String(tradeIn.isGlassSurface));
    formData.append(
      'isGlassSurfacePercents',
      String(tradeIn.isGlassSurfacePercents),
    );
    formData.append('isSpecificDamage', String(tradeIn.isSpecificDamage));
    formData.append(
      'isSpecificDamagePercents',
      String(tradeIn.isSpecificDamagePercents),
    );
    formData.append(
      'batteryCapacityMoreThen',
      String(tradeIn.batteryCapacityMoreThen),
    );
    formData.append(
      'batteryCapacityMoreThenPercents',
      String(tradeIn.batteryCapacityMoreThenPercents),
    );
    formData.append('diagonal', String(tradeIn.diagonal));
    formData.append('diagonalPercents', String(tradeIn.diagonalPercents));
    formData.append('processor', String(tradeIn.processor));
    formData.append('processorPercents', String(tradeIn.processorPercents));
    formData.append('battery', String(tradeIn.battery));
    formData.append('batteryPercents', String(tradeIn.batteryPercents));
    formData.append('deviceSet', String(tradeIn.deviceSet));
    formData.append('deviceSetPercents', String(tradeIn.deviceSetPercents));

    API.put(`${URLS.TRADE_IN}/${selectedModel.tradeIn?.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        updateModelAfterEditTradeIn(data, selectedModel);
        onClose();
        showAlert({
          message: ALERT_TEXT.MODEL_EDIT_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      shouldPreventModal
      title={`Edit trade-in for ${selectedModel.nameEn}`}
      open={open}
      onClose={onClose}
    >
      <ModelForm
        loading={loading}
        onSubmit={editTradeIn}
        onCancel={onClose}
        defaultValue={selectedModel}
      />
    </Modal>
  );
};
