import { useMemo } from 'react';
import { GadgetDto } from '../../types/gadgets.types';
import { ModelDto } from '../../types/models.types';
import { ITradeIn } from '../../types/tradeIn.types';
import { EditModelModal } from './components/ModelModal/EditModelModal';

export enum MODALS {
  CREATE_GADGET = 'CREATE_GADGET',
  EDIT_GADGET = 'EDIT_GADGET',
  DELETE_GADGET = 'DELETE_GADGET',
  CREATE_MODEL = 'CREATE_MODEL',
  EDIT_MODEL = 'EDIT_MODEL',
  DELETE_MODEL = 'DELETE_MODEL',
}

export type ModalKeys = keyof typeof MODALS;

interface chooseModalProps {
  choosenModal: ModalKeys | null;
  selectedGadget: GadgetDto | null;
  selectedModel: ModelDto | null;
  updateModelAfterEditTradeIn: (tradeIn: ITradeIn, model: ModelDto) => void;
  closeModal: () => void;
}

export const ChooseModal = ({
  selectedModel,
  selectedGadget,
  closeModal,
  updateModelAfterEditTradeIn,
  choosenModal,
}: chooseModalProps) => {
  const modal = useMemo(() => {
    switch (choosenModal) {
      case MODALS.EDIT_MODEL:
        return (
          selectedModel && (
            <EditModelModal
              updateModelAfterEditTradeIn={updateModelAfterEditTradeIn}
              selectedModel={selectedModel}
              onClose={() => closeModal()}
              open
            />
          )
        );
      default:
        return <></>;
    }
  }, [choosenModal, closeModal, selectedGadget, selectedModel]);

  return modal;
};
