import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../components/Breadcrumbs/Breadcrumbs';
import CustomTablePagination from '../../components/CustomTablePagination/CustomTablePagination';
import { DeleteOrderModal } from './components/DeleteOrderModal';
import { TableRowComponent } from './components/TableRowComponent';
import { Column } from './OrdersPage.type';
import styles from './styles.module.css';
import { useOrdersPage } from './useOrdersPage';

const columns: Column[] = [
  { name: 'status', label: 'Status', minWidth: 100 },
  { name: 'source', label: 'Source', minWidth: 100 },
  { name: 'firstName', label: 'First name', minWidth: 100 },
  { name: 'lastName', label: 'Last name', minWidth: 100 },
  { name: 'email', label: 'Email', minWidth: 100 },
  { name: 'phone', label: 'Phone number', minWidth: 100 },
  { name: 'orderNumber', label: 'Order number', minWidth: 100 },
  { name: 'address', label: 'Address', minWidth: 100 },
  { name: 'additionalAddressData', label: 'Additional address', minWidth: 100 },
  { name: 'postCode', label: 'Post code', minWidth: 100 },
  { name: 'city', label: 'City', minWidth: 100 },
  { name: 'country', label: 'Country', minWidth: 100 },
  { name: 'iban', label: 'IBAN', minWidth: 100 },
  {
    name: 'createdAt',
    label: 'Created at',
    minWidth: 170,
  },
  {
    name: 'actions',
    label: 'Actions',
    minWidth: 100,
  },
];

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Choice',
    to: '/choice',
    icon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Orders',
    to: '',
    icon: <LocalShippingIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

export const OrdersPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const {
    orders,
    metaData,
    page,
    quantity,
    order,
    setPage,
    setQuantity,
    setOrder,
    updateOrdersAfterDelete,
  } = useOrdersPage();

  const onPageDelete = (orderId: string) => {
    setOpenModal(true);
    setDeleteId(orderId);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setQuantity(+event.target.value);
    setPage(1);
  };

  return (
    <Container maxWidth="xl">
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h2">Orders</Typography>
        </div>
        <BreadcrumbsComponent linkList={linkList} />
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.name}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: 'bolder',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((row, index) => (
                  <TableRowComponent
                    row={row}
                    columns={columns}
                    onPageDelete={onPageDelete}
                    key={index}
                    status={row.status}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {metaData && (
            <CustomTablePagination
              count={metaData.pageCount}
              rowsPerPage={quantity}
              page={page - 1}
              onChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </div>
      <DeleteOrderModal
        open={openModal}
        onClose={onCloseModal}
        orderId={deleteId}
        updateOrdersAfterDelete={updateOrdersAfterDelete}
      />
    </Container>
  );
};
