import { useEffect, useReducer, useState } from 'react';
import { API } from '../../api/api';
import { URLS } from '../../api/urls';
import { useAlert } from '../../context/Alert.context';
import { IPaginationMetaDtoParameters } from '../../types/types';
import { IBusinessRequests, IBusinessRequestsResponse } from './types';
import { initialState, reducer } from './reducer/reducer';
import { actionMailsTypes } from './reducer/reducer.type';

export const useBusinessPage = () => {
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [{ businessRequests, metaData, page, quantity, order }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const formattedDate = (date: Date) => {
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
  }

  useEffect(() => {
    API.get<IBusinessRequestsResponse>(
      `${URLS.BUSINESS}?page=${page}&limit=${quantity}&order=${order}`,
    )
      .then(({ data }) => {
        actions.setBusinessRequests(data.data);
        actions.setMetaData(data.meta);
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  }, [page, quantity, order]);

  const actions = {
    setBusinessRequests: (data: IBusinessRequests[]) => {
      const updatedData = data.map((mail) => ({
        ...mail,
        createdAt: formattedDate(new Date(mail.createdAt)),
      }));

      return dispatch({
        type: actionMailsTypes.SET_BUSINESS_REQUEST,
        payload: updatedData,
      });
    },
    setMetaData: (metaData: IPaginationMetaDtoParameters) => {
      return dispatch({
        type: actionMailsTypes.SET_META_DATA,
        payload: metaData,
      });
    },
    setPage: (page: number) => {
      return dispatch({
        type: actionMailsTypes.SET_PAGE,
        payload: page,
      });
    },
    setQuantity: (quantity: number) => {
      return dispatch({
        type: actionMailsTypes.SET_QUANTITY,
        payload: quantity,
      });
    },
    setOrder: (order: string) => {
      return dispatch({
        type: actionMailsTypes.SET_ORDER,
        payload: order,
      });
    },
  };

  const updateRequestAfterDelete = (id: string) => {
    const updatedMails = businessRequests.filter((businessRequest) => businessRequest.id !== id);
    dispatch({
      type: actionMailsTypes.SET_BUSINESS_REQUEST,
      payload: updatedMails,
    });
  };

  return {
    businessRequests,
    metaData,
    loading,
    page,
    quantity,
    order,
    updateRequestAfterDelete,
    ...actions,
  };
};
