import DeleteIcon from '@mui/icons-material/Delete';
import { TableCell, TableRow } from '@mui/material';
import { Column, IOrder } from '../OrdersPage.type';
import styles from '../styles.module.css';

type Props = {
  columns: Column[];
  row: IOrder;
  onPageDelete: (orderId: string) => void;
  status?: string;
};

export const TableRowComponent = ({
  row,
  columns,
  onPageDelete,
  status,
}: Props) => {
  const handleColumnValue = (column: Column) => {
    switch (column.name) {
      // case 'communicationChannel':
      //   return !!row[column.name]
      //     ? replaceUnderscoreWithSpace(row[column.name])
      //     : row[column.name];
      // case 'source':
      //   return !!row[column.name]
      //     ? replaceUnderscoreWithSpace(row[column.name])
      //     : row[column.name];
      case 'actions':
        return;
      default:
        return row[column.name];
    }
  };
  return (
    <TableRow
      style={{
        backgroundColor:
          status && status === 'active'
            ? 'rgb(113 232 115 / 50%)'
            : 'rgb(255 0 0 / 50%)',
      }}
      hover
      role="checkbox"
      tabIndex={-1}
    >
      {columns.map((column) => {
        const value: any = handleColumnValue(column);

        if (column.name === 'actions') {
          return (
            <TableCell key={column.name} align={column.align}>
              <div className={styles.action}>
                <DeleteIcon
                  color="error"
                  onClick={() => onPageDelete(row.id)}
                />
              </div>
            </TableCell>
          );
        }

        return (
          <TableCell key={column.name} align={column.align}>
            {typeof value === 'object' && value !== null
              ? `${value.models.name} / ${value.serviceInfo.shortTitleEn}`
              : value}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
