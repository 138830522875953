import { useState } from 'react';
import { API } from '../../../api/api';
import { URLS } from '../../../api/urls';
import { DeleteConfirmationModal } from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import { ALERT_TEXT } from '../../../constants/content';
import { useAlert } from '../../../context/Alert.context';

interface DeletePageModalProps {
  open: boolean;
  onClose: () => void;
  orderId: string;
  updateOrdersAfterDelete: (orderId: string) => void;
}

export const DeleteOrderModal = ({
  open,
  onClose,
  orderId,
  updateOrdersAfterDelete,
}: DeletePageModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const onDelete = () => {
    setLoading(true);
    API.delete(`${URLS.ORDERS}/${orderId}`)
      .then(() => {
        updateOrdersAfterDelete(orderId);
        onClose();
        showAlert({
          message: ALERT_TEXT.ORDERS_DELETE_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <DeleteConfirmationModal
      loading={loading}
      onClose={onClose}
      open={open}
      titleItem={'order'}
      onDelete={onDelete}
      textMessage="Are you sure you want to change status to done"
      acceptButtonText={'DONE'}
    />
  );
};
