export enum ALERT_TEXT {
  MODEL_CREATED_SUCCESS = 'Model was created',
  INFO_EDITED_SUCCESS = 'Info was edited',
  INFO_CREATED_SUCCESS = 'Info was created',
  MODEL_EDIT_SUCCESS = 'Model was edited',
  MODEL_DELETE_SUCCESS = 'Model was deleted',
  GADGET_CREATED_SUCCESS = 'Gadget was created',
  GADGET_EDIT_SUCCESS = 'Gadget was edited',
  GADGET_DELETE_SUCCESS = 'Gadget was deleted',
  SERVICE_DELETE_SUCCESS = 'Service was deleted',
  SERVICE_EDIT_SUCCESS = 'Service was edited',
  PAGE_DELETE_SUCCESS = 'Page was deleted',
  PAGE_EDIT_SUCCESS = 'Page was edit',
  PAGE_CREATED_SUCCESS = 'Page was created',
  MAIL_DELETE_SUCCESS = 'Mail was deleted',
  BUSINESS_REQUEST_SUCCESS = 'Mail was deleted',
  POST_DELETE_SUCCESS = 'Post was deleted',
  ORDERS_DELETE_SUCCESS = 'Order was done',
  RECOVERY_REQUEST_SUCCESS = 'Recovery request was done',
  ERROR = 'Something goes wrong',
}
