import { useEffect, useReducer, useState } from 'react';
import { API } from '../../api/api';
import { URLS } from '../../api/urls';
import { useAlert } from '../../context/Alert.context';
import { IPaginationMetaDtoParameters } from '../../types/types';
import { IOrder, IOrderResponse } from './OrdersPage.type';
import { initialState, reducer } from './reducer/reducer';
import { actionOrdersTypes } from './reducer/reducer.type';

export const useOrdersPage = () => {
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [{ orders, metaData, page, quantity, order }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  useEffect(() => {
    API.get<IOrderResponse>(
      `${URLS.ORDERS}?page=${page}&limit=${quantity}&order=${order}`,
    )
      .then(({ data }) => {
        actions.setOrders(data.data);
        actions.setMetaData(data.meta);
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  }, [page, quantity, order]);

  const actions = {
    setOrders: (data: IOrder[]) => {
      const updatedData = data.map((order) => ({
        ...order,
        createdAt: new Date(order.createdAt).toString(),
      }));

      return dispatch({
        type: actionOrdersTypes.SET_ORDERS,
        payload: updatedData,
      });
    },
    setMetaData: (metaData: IPaginationMetaDtoParameters) => {
      return dispatch({
        type: actionOrdersTypes.SET_META_DATA,
        payload: metaData,
      });
    },
    setPage: (page: number) => {
      return dispatch({
        type: actionOrdersTypes.SET_PAGE,
        payload: page,
      });
    },
    setQuantity: (quantity: number) => {
      return dispatch({
        type: actionOrdersTypes.SET_QUANTITY,
        payload: quantity,
      });
    },
    setOrder: (order: string) => {
      return dispatch({
        type: actionOrdersTypes.SET_ORDER,
        payload: order,
      });
    },
  };

  const updateOrdersAfterDelete = (orderId: string) => {
    const updatedOrders = orders.map((order: any) =>
      order.id === orderId ? { ...order, status: 'done' } : order,
    );

    dispatch({
      type: actionOrdersTypes.SET_ORDERS,
      payload: updatedOrders,
    });
  };

  return {
    orders,
    metaData,
    loading,
    page,
    quantity,
    order,
    updateOrdersAfterDelete,
    ...actions,
  };
};
