import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HomeIcon from '@mui/icons-material/Home';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { Container, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../components/Breadcrumbs/Breadcrumbs';
import { Spinner } from '../../components/Spinner/Spinner';
import { GadgetDto } from '../../types/gadgets.types';
import { ChooseModal, MODALS } from './chooseModal';
import { Row } from './components/Row';
import styles from './styles.module.css';
import { useGadgetsPage } from './useTradeInPage';

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Choice',
    to: '/choice',
    icon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Trade-in',
    to: '',
    icon: <LocalMallIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

export const TradeInPage = () => {
  const {
    gadgets,
    loading,
    selectedGadget,
    selectedModel,
    choosenModal,
    closeModal,
    setModal,
    setSelectedModel,
    updateModelAfterEditTradeIn,
  } = useGadgetsPage();

  if (loading)
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );

  return (
    <Container maxWidth="xl">
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h2">Trade-in</Typography>
        </div>
        <BreadcrumbsComponent linkList={linkList} />
        {!gadgets.length && (
          <div className={styles.loader}>
            <Typography variant="h2">Table is empty.</Typography>
          </div>
        )}
        {!!gadgets.length && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  <TableCell />
                  {['Gadget'].map((item) => (
                    <TableCell className={styles.text} key={item}>
                      {item}
                    </TableCell>
                  ))}
                  <TableCell className={styles.text} align="right">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gadgets.map((row: GadgetDto) => (
                  <Row
                    key={row.id}
                    row={row}
                    onModelEdit={(model) => {
                      setSelectedModel({
                        ...model,
                        gadgetId: row.id,
                        gadgetSlug: row.slug,
                      });
                      setModal(MODALS.EDIT_MODEL);
                    }}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <ChooseModal
        choosenModal={choosenModal}
        updateModelAfterEditTradeIn={updateModelAfterEditTradeIn}
        selectedGadget={selectedGadget}
        selectedModel={selectedModel}
        closeModal={closeModal}
      />
    </Container>
  );
};
