import { useState } from 'react';
import { API } from '../../../api/api';
import { URLS } from '../../../api/urls';
import { DeleteConfirmationModal } from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import { ALERT_TEXT } from '../../../constants/content';
import { useAlert } from '../../../context/Alert.context';

interface DeletePageRecoveryRequestProps {
  open: boolean;
  onClose: () => void;
  recoveryRequestId: string;
  updateRecoveryRequestAfterDelete: (recoveryRequestId: string) => void;
}

export const DeleteRecoveryRequestModal = ({
  open,
  onClose,
  recoveryRequestId,
  updateRecoveryRequestAfterDelete,
}: DeletePageRecoveryRequestProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const onDelete = () => {
    setLoading(true);
    API.delete(`${URLS.RECOVERY_REQUEST}/${recoveryRequestId}`)
      .then(() => {
        updateRecoveryRequestAfterDelete(recoveryRequestId);
        onClose();
        showAlert({
          message: ALERT_TEXT.RECOVERY_REQUEST_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <DeleteConfirmationModal
      loading={loading}
      onClose={onClose}
      open={open}
      titleItem={'recoveryRequest'}
      onDelete={onDelete}
      textMessage="Are you sure you want to change status to done"
      acceptButtonText={'DONE'}
    />
  );
};
