export enum URLS {
  GADGETS = '/gadgets',
  MODELS = '/models',
  SERVICE_INFO = '/service-info',
  SERVICE = '/services',
  POSTS = '/posts',
  MAIL = '/mail',
  ORDERS = '/orders',
  TRADE_IN = '/trade-in',
  PAGES = 'pages',
  RESERVATIONS = '/reservations',
  RECOVERY_REQUEST = '/recovery-request',
  BUSINESS = '/business',
}
