import { Modal } from '../../../components/Modal/Modal';
import { Typography } from '@mui/material';
import styles from './styles.module.css'

interface PreviewBusinessRequestModalProps {
  open: boolean;
  onClose: () => void;
  data: any;
}

export const PreviewBusinessRequestModal = ({ open, onClose, data  }: PreviewBusinessRequestModalProps) => {
  return (
    <Modal title="Request" open={open} onClose={onClose}>
      <div>
        <Typography variant="h5">{data?.createdAt}</Typography>
        <Typography variant="h5">{data?.idForClient}</Typography>
        <div className={styles.body}>
          <div className={styles.block}>
            <Typography className={styles.title} variant="h5">First and Last name</Typography>
            <Typography variant="h6">{data?.name}</Typography>
          </div>
          <div className={styles.block}>
            <Typography className={styles.title} variant="h5">Company</Typography>
            <Typography variant="h6">{data?.company}</Typography>
          </div>
          <div className={styles.block}>
            <Typography className={styles.title} variant="h5">Email</Typography>
            <Typography variant="h6">{data?.email}</Typography>
          </div>
          <div className={styles.block}>
            <Typography className={styles.title} variant="h5">Phone number</Typography>
            <Typography variant="h6">{data?.phoneNumber}</Typography>
          </div>
          <div className={styles.block}>
            <Typography className={styles.title} variant="h5">Selected plan</Typography>
            <Typography variant="h6">{data?.selectedPlan}</Typography>
          </div>
          <div className={styles.block}>
            <Typography className={styles.title} variant="h5">Additional Info</Typography>
            <Typography variant="h6">{data?.additionalInfo || '-'}</Typography>
          </div>
        </div>
      </div>
    </Modal>
  )

}