import { useEffect, useReducer, useState } from 'react';
import { API } from '../../api/api';
import { URLS } from '../../api/urls';
import { useAlert } from '../../context/Alert.context';
import { IPaginationMetaDtoParameters } from '../../types/types';
import {
  IRecoveryRequest,
  IRecoveryRequestResponse,
} from './RecoveryRequestPage.type';
import { initialState, reducer } from './reducer/reducer';
import { actionRecoveryRequestTypes } from './reducer/reducer.type';

export const useRecoveryRequestPage = () => {
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(true);
  const [{ recoveryRequests, metaData, page, quantity, order }, dispatch] =
    useReducer(reducer, initialState);

  useEffect(() => {
    API.get<IRecoveryRequestResponse>(
      `${URLS.RECOVERY_REQUEST}?page=${page}&limit=${quantity}&order=${order}`,
    )
      .then(({ data }) => {
        actions.setRecoveryRequests(data.data);
        actions.setMetaData(data.meta);
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  }, [page, quantity, order]);

  const actions = {
    setRecoveryRequests: (data: IRecoveryRequest[]) => {
      const updatedData = data.map((recoveryRequest) => ({
        ...recoveryRequest,
        createdAt: new Date(recoveryRequest.createdAt).toString(),
      }));

      return dispatch({
        type: actionRecoveryRequestTypes.SET_RECOVERY_REQUESTS,
        payload: updatedData,
      });
    },
    setMetaData: (metaData: IPaginationMetaDtoParameters) => {
      return dispatch({
        type: actionRecoveryRequestTypes.SET_META_DATA,
        payload: metaData,
      });
    },
    setPage: (page: number) => {
      return dispatch({
        type: actionRecoveryRequestTypes.SET_PAGE,
        payload: page,
      });
    },
    setQuantity: (quantity: number) => {
      return dispatch({
        type: actionRecoveryRequestTypes.SET_QUANTITY,
        payload: quantity,
      });
    },
    setOrder: (order: string) => {
      return dispatch({
        type: actionRecoveryRequestTypes.SET_ORDER,
        payload: order,
      });
    },
  };

  const updateRecoveryRequestAfterDelete = (recoveryRequestsId: string) => {
    const updatedRecoveryRequests = recoveryRequests.map(
      (recoveryRequests: any) =>
        recoveryRequests.id === recoveryRequestsId
          ? { ...recoveryRequests, status: 'done' }
          : recoveryRequests,
    );
    dispatch({
      type: actionRecoveryRequestTypes.SET_RECOVERY_REQUESTS,
      payload: updatedRecoveryRequests,
    });
  };

  return {
    recoveryRequests,
    metaData,
    loading,
    page,
    quantity,
    order,
    updateRecoveryRequestAfterDelete,
    ...actions,
  };
};
