import { actionOrdersTypes, ActionTypes, IInitialState } from './reducer.type';

export const initialState: IInitialState = {
  orders: [],
  metaData: null,
  page: 1,
  quantity: 10,
  order: 'DESC',
};

export const reducer = (
  state = initialState,
  action: ActionTypes,
): IInitialState => {
  switch (action.type) {
    case actionOrdersTypes.SET_ORDERS:
      return { ...state, orders: action.payload };
    case actionOrdersTypes.SET_META_DATA:
      return { ...state, metaData: action.payload };
    case actionOrdersTypes.SET_PAGE:
      return { ...state, page: action.payload };
    case actionOrdersTypes.SET_QUANTITY:
      return { ...state, quantity: action.payload };
    case actionOrdersTypes.SET_ORDER:
      return { ...state, order: action.payload };
    default:
      return state;
  }
};
