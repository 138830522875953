import {
  actionRecoveryRequestTypes,
  ActionTypes,
  IInitialState,
} from './reducer.type';

export const initialState: IInitialState = {
  recoveryRequests: [],
  metaData: null,
  page: 1,
  quantity: 10,
  order: 'DESC',
};

export const reducer = (
  state = initialState,
  action: ActionTypes,
): IInitialState => {
  switch (action.type) {
    case actionRecoveryRequestTypes.SET_RECOVERY_REQUESTS:
      return { ...state, recoveryRequests: action.payload };
    case actionRecoveryRequestTypes.SET_META_DATA:
      return { ...state, metaData: action.payload };
    case actionRecoveryRequestTypes.SET_PAGE:
      return { ...state, page: action.payload };
    case actionRecoveryRequestTypes.SET_QUANTITY:
      return { ...state, quantity: action.payload };
    case actionRecoveryRequestTypes.SET_ORDER:
      return { ...state, order: action.payload };
    default:
      return state;
  }
};
