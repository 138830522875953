export interface ITradeIn {
  id: string;
  maxPrice: number;
  minPrice: number;
  hardDisk: ITradeInHardDisk;
  memory: IMemory;
  memoryPercents: IMemoryPercents;
  isWorkFine: IIsWorkFine;
  isWorkFinePercents: IIsWorkFinePercents;
  isScreenFine: IIsScreenFine;
  isScreenFinePercents: IIsScreenFinePercents;
  screenFrameState: IScreenFrameState;
  screenFrameStatePercents: IScreenFrameStatePercents;
  isGlassSurface: IIsGlassSurface;
  isGlassSurfacePercents: IIsGlassSurfacePercents;
  isSpecificDamage: IIsSpecificDamage;
  isSpecificDamagePercents: IIsSpecificDamagePercents;
  batteryCapacityMoreThen: IBatteryCapacityMoreThen;
  batteryCapacityMoreThenPercents: IBatteryCapacityMoreThenPercents;
  diagonal: IDiagonal;
  diagonalPercents: IDiagonalPercents;
  processor: IProcessor;
  processorPercents: IProcessorPercents;
  battery: IBattery;
  batteryPercents: IBatteryPercents;
  deviceSet: IDeviceSet;
  deviceSetPercents: IDeviceSetPercents;
}

// Hard disk
export enum TradeInHardDiskList {
  'hardDisk64GB' = '64',
  'hardDisk128GB' = '128',
  'hardDisk256GB' = '256',
  'hardDisk512GB' = '512',
  'hardDisk1TB' = '1TB',
  'hardDisk2TB' = '2TB',
  'hardDisk4TB' = '4TB',
  'hardDisk8TB' = '8TB',
}

export interface ITradeInHardDisk {
  hardDisk64GB: '64';
  hardDisk128GB: '128';
  hardDisk256GB: '256';
  hardDisk512GB: '512';
  hardDisk1TB: '1TB';
  hardDisk2TB: '2TB';
  hardDisk4TB: '4TB';
  hardDisk8TB: '8TB';
}

// Screen frame state
export interface IScreenFrameState {
  screenFrameStateNew: string;
  screenFrameStateVeryGood: string;
  screenFrameStateNormal: string;
  screenFrameStateFine: string;
}

export enum ScreenFrameStateList {
  screenFrameStateNew = 'new',
  screenFrameStateVeryGood = 'veryGood',
  screenFrameStateNormal = 'normal',
  screenFrameStateFine = 'fine',
}

export interface IScreenFrameStatePercents {
  screenFrameStateNewPercent: string;
  screenFrameStateVeryGoodPercent: string;
  screenFrameStateNormalPercent: string;
  screenFrameStateFinePercent: string;
}

export enum ScreenFrameStatePercentsList {
  screenFrameStateNewPercent = 'new',
  screenFrameStateVeryGoodPercent = 'veryGood',
  screenFrameStateNormalPercent = 'normal',
  screenFrameStateFinePercent = 'fine',
}

//Is work fine
export interface IIsWorkFine {
  isWorkFineYes: string;
  isWorkFineNo: string;
}

export enum IsWorkFineList {
  isWorkFineYes = 'yes',
  isWorkFineNo = 'no',
}

export interface IIsWorkFinePercents {
  isWorkFineYesPercent: string;
  isWorkFineNoPercent: string;
}

export enum IsWorkFinePercentsList {
  isWorkFineYesPercent = 'yesPercent',
  isWorkFineNoPercent = 'noPercent',
}

//Is glass surface
export interface IIsGlassSurface {
  isGlassSurfaceYes: string;
  isGlassSurfaceNo: string;
}

export enum IsGlassSurfaceList {
  isGlassSurfaceYes = 'yes',
  isGlassSurfaceNo = 'no',
}

export interface IIsGlassSurfacePercents {
  isGlassSurfaceYesPercent: string;
  isGlassSurfaceNoPercent: string;
}

export enum IsGlassSurfacePercentsList {
  isGlassSurfaceYesPercent = 'yesPercent',
  isGlassSurfaceNoPercent = 'noPercent',
}

// Is screen fine
export interface IIsScreenFine {
  isScreenFineYes: string;
  isScreenFineNo: string;
}

export enum IsScreenFineList {
  isScreenFineYes = 'yes',
  isScreenFineNo = 'no',
}

export interface IIsScreenFinePercents {
  isScreenFineYesPercent: string;
  isScreenFineNoPercent: string;
}

export enum IsScreenFinePercentsList {
  isScreenFineYesPercent = 'yesPercent',
  isScreenFineNoPercent = 'noPercent',
}

// Is specific damage
export interface IIsSpecificDamage {
  isSpecificDamageYes: string;
  isSpecificDamageNo: string;
}

export enum IsSpecificDamageList {
  isSpecificDamageYes = 'yes',
  isSpecificDamageNo = 'no',
}

export interface IIsSpecificDamagePercents {
  isSpecificDamageYesPercent: string;
  isSpecificDamageNoPercent: string;
}

export enum IsSpecificDamagePercentsList {
  isSpecificDamageYesPercent = 'yesPercent',
  isSpecificDamageNoPercent = 'noPercent',
}

// Battery capacity more then
export interface IBatteryCapacityMoreThen {
  batteryCapacityMoreThenYes: string;
  batteryCapacityMoreThenNo: string;
  batteryCapacityMoreThenNotSure: string;
}

export enum BatteryCapacityMoreThenList {
  batteryCapacityMoreThenYes = 'yes',
  batteryCapacityMoreThenNo = 'no',
  batteryCapacityMoreThenNotSure = 'not sure',
}

export interface IBatteryCapacityMoreThenPercents {
  batteryCapacityMoreThenYesPercent: string;
  batteryCapacityMoreThenNoPercent: string;
  batteryCapacityMoreThenNotSurePercent: string;
}

export enum BatteryCapacityMoreThenPercentsList {
  batteryCapacityMoreThenYesPercent = 'yes',
  batteryCapacityMoreThenNoPercent = 'no',
  batteryCapacityMoreThenNotSurePercent = 'not sure',
}

// Diagonal
export interface IDiagonal {
  diagonal13: string;
  diagonal14: string;
  diagonal15: string;
  diagonal16: string;
}

export enum DiagonalList {
  diagonal13 = '13',
  diagonal14 = '14',
  diagonal15 = '15',
  diagonal16 = '16',
}

export interface IDiagonalPercents {
  diagonal13Percent: string;
  diagonal14Percent: string;
  diagonal15Percent: string;
  diagonal16Percent: string;
}

export enum DiagonalPercentsList {
  diagonal13Percent = '13',
  diagonal14Percent = '14',
  diagonal15Percent = '15',
  diagonal16Percent = '16',
}

// Memory
export interface IMemory {
  memory8GB: string;
  memory16GB: string;
  memory24GB: string;
  memory32GB: string;
  memory64GB: string;
  memory96GB: string;
}

export enum MemoryList {
  memory8GB = '8',
  memory16GB = '16',
  memory24GB = '24',
  memory32GB = '32',
  memory64GB = '64',
  memory96GB = '96',
}

export interface IMemoryPercents {
  memory8GBPercent: string;
  memory16GBPercent: string;
  memory24GBPercent: string;
  memory32GBPercent: string;
  memory64GBPercent: string;
  memory96GBPercent: string;
}

export enum MemoryPercentsList {
  memory8GBPercent = '8',
  memory16GBPercent = '16',
  memory24GBPercent = '24',
  memory32GBPercent = '32',
  memory64GBPercent = '64',
  memory96GBPercent = '96',
}

// Processor
export interface IProcessor {
  processorI3: string;
  processorI5: string;
  processorI7: string;
  processorI9: string;
  processorM1: string;
  processorM1ProCore14: string;
  processorM1ProCore16: string;
  processorM1MaxCore24: string;
  processorM1MaxCore32: string;
  processorM2: string;
  processorM2Core8: string;
  processorM2Core10: string;
  processorM2ProCore19: string;
  processorM2MaxCore30: string;
  processorM2MaxCore38: string;
}

export enum ProcessorList {
  processorI3 = 'i3',
  processorI5 = 'i5',
  processorI7 = 'i7',
  processorI9 = 'i9',
  processorM1 = 'M1',
  processorM1ProCore14 = 'M1 Pro 14-core GPU',
  processorM1ProCore16 = 'M1 Pro 16-core GPU',
  processorM1MaxCore24 = 'M1 Max 24-core GPU',
  processorM1MaxCore32 = 'M1 Max 32-core GPU',
  processorM2 = 'M2',
  processorM2Core8 = 'M2 8-core GPU',
  processorM2Core10 = 'M2 10-core GPU',
  processorM2ProCore19 = 'M2 Pro 19-core GPU',
  processorM2MaxCore30 = 'M2 Max 30-core GPU',
  processorM2MaxCore38 = 'M2 Max 38-core GPU',
}

export interface IProcessorPercents {
  processorI3Percent: string;
  processorI5Percent: string;
  processorI7Percent: string;
  processorI9Percent: string;
  processorM1Percent: string;
  processorM1ProCore14Percent: string;
  processorM1ProCore16Percent: string;
  processorM1MaxCore24Percent: string;
  processorM1MaxCore32Percent: string;
  processorM2Percent: string;
  processorM2Core8Percent: string;
  processorM2Core10Percent: string;
  processorM2ProCore19Percent: string;
  processorM2MaxCore30Percent: string;
  processorM2MaxCore38Percent: string;
}

export enum ProcessorPercentsList {
  processorI3Percent = 'i3',
  processorI5Percent = 'i5',
  processorI7Percent = 'i7',
  processorI9Percent = 'i9',
  processorM1Percent = 'M1',
  processorM1ProCore14Percent = 'M1 Pro 14-core GPU',
  processorM1ProCore16Percent = 'M1 Pro 16-core GPU',
  processorM1MaxCore24Percent = 'M1 Max 24-core GPU',
  processorM1MaxCore32Percent = 'M1 Max 32-core GPU',
  processorM2Percent = 'M2',
  processorM2Core8Percent = 'M2 8-core GPU',
  processorM2Core10Percent = 'M2 10-core GPU',
  processorM2ProCore19Percent = 'M2 Pro 19-core GPU',
  processorM2MaxCore30Percent = 'M2 Max 30-core GPU',
  processorM2MaxCore38Percent = 'M2 Max 38-core GPU',
}

// Battery
export interface IBattery {
  batteryUpTo500: string;
  batteryFrom500: string;
  batteryRecommendedService: string;
  batteryDoNotKnow: string;
}

export enum BatteryList {
  batteryUpTo500 = 'up to 500',
  batteryFrom500 = 'from 500',
  batteryRecommendedService = 'recommended service',
  batteryDoNotKnow = 'do not know',
}

export interface IBatteryPercents {
  batteryUpTo500Percent: string;
  batteryFrom500Percent: string;
  batteryRecommendedServicePercent: string;
  batteryDoNotKnowPercent: string;
}

export enum BatteryPercentsList {
  batteryUpTo500Percent = 'up to 500',
  batteryFrom500Percent = 'from 500',
  batteryRecommendedServicePercent = 'recommended service',
  batteryDoNotKnowPercent = 'do not know',
}

// Device set
export interface IDeviceSet {
  deviceSetFull: string;
  deviceSetOnlyCharger: string;
  deviceSetOnlyBox: string;
  deviceSetOnlyComputer: string;
}

export enum DeviceSetList {
  deviceSetFull = 'full',
  deviceSetOnlyCharger = 'only charger',
  deviceSetOnlyBox = 'only box',
  deviceSetOnlyComputer = 'only computer',
}

export interface IDeviceSetPercents {
  deviceSetFullPercent: string;
  deviceSetOnlyChargerPercent: string;
  deviceSetOnlyBoxPercent: string;
  deviceSetOnlyComputerPercent: string;
}

export enum DeviceSetPercentsList {
  deviceSetFullPercent = 'up to 500',
  deviceSetOnlyChargerPercent = 'from 500',
  deviceSetOnlyBoxPercent = 'recommended service',
  deviceSetOnlyComputerPercent = 'do not know',
}

export const enum TRADE_FIELDS {
  IS_WORK_FINE = 'isWorkFine',
  IS_SCREEN_FINE = 'isScreenFine',
  SCREEN_FRAME_STATE = 'screenFrameState',
  IS_GLASS_SURFACE = 'isGlassSurface',
  IS_SPECIFIC_DAMAGE = 'isSpecificDamage',
  BATTERY_CAPACITY_MORE_THEN = 'batteryCapacityMoreThen',
  DIAGONAL = 'diagonal',
  MEMORY = 'memory',
  PROCESSOR = 'processor',
  BATTERY = 'battery',
  DEVICE_SET = 'deviceSet',
}

export interface IGadgetTradeInFields {
  iphone: string[];
  macbook: string[];
}

export const gadgetTradeInFields: IGadgetTradeInFields = {
  iphone: [
    'hardDisk',
    'isWorkFine',
    'isScreenFine',
    'screenFrameState',
    'isGlassSurface',
    'isSpecificDamage',
    'batteryCapacityMoreThen',
  ],
  macbook: [
    'diagonal',
    'hardDisk',
    'memory',
    'processor',
    'screenFrameState',
    'isWorkFine',
    'battery',
    'deviceSet',
  ],
};
