import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import { Authorization } from '../components/Authorization/Authorization';
import { Header } from '../components/Header/Header';
import { RequestResetPassword } from '../components/ResetPassword/RequestResetPassword/RequestResetPassword';
import { ResetPassword } from '../components/ResetPassword/ResetPassword';
import { useAuth } from '../context/Auth.context';
import { BlogPage } from '../pages/BlogPage/BlogPage';
import { CreatePostPage } from '../pages/BlogPage/subPages/CreatePostPage/CreatePostPage';
import { EditPostPage } from '../pages/BlogPage/subPages/EditPostPage/EditPostPage';
import BookTimePage from '../pages/BookTimePage/BookTimePage';
import { BusinessPage } from '../pages/BusinessPage/BusinessPage';
import { ChoicePage } from '../pages/ChoicePage/ChoicePage';
import { GadgetsPage } from '../pages/GadgetsPage/GadgetsPage';
import { MailsPage } from '../pages/MailsPage/MailsPage';
import { OrdersPage } from '../pages/OrdersPage/OrdersPage';
import { PagesPage } from '../pages/PagesPage/PagesPage';
import { ProjectsPage } from '../pages/ProjectsPage/ProjectsPage';
import { RecoveryRequestPage } from '../pages/RecoveryRequestPage/RecoveryRequestPage';
import { ServiceInfoPage } from '../pages/ServicesPage/ServiceInfoPage';
import { TradeInPage } from '../pages/TradeInPage/TradeInPage';
import { locations } from '../utils/locations';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <ProjectsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_app.path,
        element: (
          <ProtectedRoute>
            <ChoicePage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_blog.path,
        element: (
          <ProtectedRoute>
            <BlogPage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_blog_new.path,
        element: (
          <ProtectedRoute>
            <CreatePostPage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_blog_edit.path,
        element: (
          <ProtectedRoute>
            <EditPostPage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_services.path,
        element: (
          <ProtectedRoute>
            <ServiceInfoPage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_gadgets.path,
        element: (
          <ProtectedRoute>
            <GadgetsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_business.path,
        element: (
          <ProtectedRoute>
            <BusinessPage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_mails.path,
        element: (
          <ProtectedRoute>
            <MailsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_pages.path,
        element: (
          <ProtectedRoute>
            <PagesPage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_trade_in.path,
        element: (
          <ProtectedRoute>
            <TradeInPage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_orders.path,
        element: (
          <ProtectedRoute>
            <OrdersPage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_book_time.path,
        element: (
          <ProtectedRoute>
            <BookTimePage />
          </ProtectedRoute>
        ),
      },
      {
        path: locations.choice_recovery_request.path,
        element: (
          <ProtectedRoute>
            <RecoveryRequestPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/login',
    element: <AuthorizationLayout />,
    children: [
      {
        path: '',
        element: <Authorization />,
      },
    ],
  },
  {
    path: '/reset-password',
    element: <AuthorizationLayout />,
    children: [
      {
        path: ':token',
        element: <ResetPassword />,
      },
      {
        path: '',
        element: <RequestResetPassword />,
      },
    ],
  },
]);

function AuthorizationLayout() {
  return <Outlet />;
}

function MainLayout() {
  const { isAuth } = useAuth();
  if (!isAuth) {
    return <Navigate to="/login" replace />;
  }
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { isAuth } = useAuth();

  if (!isAuth) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
}
