import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, TextField, Typography } from '@mui/material';
import { splitLabel } from './ModelForm';
import styles from './ModelModal.module.css';

type Props = {
  headerText: string;
  buttonText: string;
  listKeys: string[];
  value: any;
  label: string;
  percentsListKeys: string[];
  valuePercents: any;
  labelPercents: string;
  handleCreateButton: () => void;
  handleChangeValue: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleChangePercentsValue: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleRemoveButton: (key: string, index: number) => void;
};

export const CardFieldsLayout = ({
  headerText,
  buttonText,
  handleCreateButton,
  handleChangeValue,
  handleChangePercentsValue,
  handleRemoveButton,
  listKeys,
  value,
  label,
  percentsListKeys,
  valuePercents,
  labelPercents,
}: Props) => {
  return (
    <Card className={styles.card}>
      <Typography textAlign={'center'} variant="h5">
        {headerText}
      </Typography>
      <Button onClick={handleCreateButton} variant="contained">
        {buttonText}
      </Button>
      {listKeys.length > 0 &&
        listKeys.map((key: string, index: number) => (
          <div className={styles.cardItems} key={index}>
            <TextField
              name={key}
              value={(value as any)[key] || ''}
              label={splitLabel(key, label)}
              variant="standard"
              required
              onChange={handleChangeValue}
              className={styles.cardInput}
            />
            <TextField
              name={percentsListKeys[index]}
              value={(valuePercents as any)[`${key}Percent`] || ''}
              label={`${splitLabel(key, labelPercents)} percent`}
              variant="standard"
              required
              onChange={handleChangePercentsValue}
              className={styles.cardInput}
            />
            <DeleteIcon
              color="error"
              onClick={() => handleRemoveButton(key, index)}
              className={styles.deleteButton}
            />
          </div>
        ))}
    </Card>
  );
};
