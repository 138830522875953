export const transformReservedTime = (time: string) => {
  const reservedDate = new Date(time);
  const formattedDate = reservedDate.toISOString().split('T')[0];
  const formattedTime = reservedDate.toTimeString().slice(0, 5);

  const reservationData = {
    date: formattedDate,
    time: formattedTime,
  };

  return reservationData;
};
