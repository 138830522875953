import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { Star } from '../../../../../img/Star';
import {
  ServiceIcon,
  ServiceInfo,
} from '../../../../../types/serviceInfo.types';
import { Service } from '../../../../../types/services.types';
import { getIconByName } from '../../../../ServicesPage/utils/ServiceInfoIcons';
import { CreateServiceModal } from './modals/CreateServiceModal';
import { DeleteServiceModal } from './modals/DeleteServiceModal';
import { EditServiceModal } from './modals/EditServiceModal';
import styles from './styles.module.css';

interface ServicesProps {
  services?: Service[];
  onAdd: (service: Service) => void;
  onEdit: (service: Service) => void;
  onDelete: (id: string) => void;
  serviceInfo: ServiceInfo[];
}

export const Services = ({
  services,
  onAdd,
  serviceInfo,
  onDelete,
  onEdit,
}: ServicesProps) => {
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<Service | null>(null);

  const getServiceInfoTitleAndIcon = (id: string) => {
    const { icon = '', shortTitleEn = '' } =
      serviceInfo.find((item) => item.id === id) || {};
    return { icon, shortTitleEn };
  };

  const getServiceInfoHtml = (id: string) => {
    const { icon, shortTitleEn } = getServiceInfoTitleAndIcon(id);
    const iconName = (
      Object.values(ServiceIcon).includes(icon as ServiceIcon)
        ? icon
        : 'FrontCamera'
    ) as ServiceIcon;
    return (
      <div className={styles.serviceName}>
        <div>{getIconByName(iconName)}</div>
        <Typography variant="h6">
          {shortTitleEn.length < 50
            ? shortTitleEn
            : shortTitleEn.slice(0, 50) + '...'}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <div className={styles.services}>
        <div className={styles.header}>
          <Typography variant="h6">Services</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenCreate(true)}
          >
            ADD
          </Button>
        </div>
        <div className={styles.container}>
          {!services?.length && (
            <div className={styles.noContent}>
              <Typography variant="h6">No services.</Typography>
            </div>
          )}
          {!!services?.length && (
            <div className={styles.servicesViewWrapper}>
              {services.map((service: any, id) => (
                <div
                  key={service.id || id}
                  className={styles.serviceItemWrapper}
                >
                  {service.isPopular && (
                    <div className={styles.popular}>
                      <Star />
                    </div>
                  )}
                  <div className={styles.serviceItem}>
                    <div className={styles.serviceIconBlock}>
                      {getServiceInfoHtml(service.serviceInfoId)}
                    </div>
                    <div className={styles.serviceActions}>
                      {service.id ? (
                        <>
                          <EditIcon
                            onClick={() => {
                              setSelectedService(service);
                              setOpenEdit(true);
                            }}
                          />
                          <DeleteIcon
                            color="error"
                            onClick={() => {
                              setSelectedService(service);
                              setOpenDelete(true);
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <CreateServiceModal
        onAdd={onAdd}
        serviceInfo={serviceInfo}
        open={openCreate}
        onClose={() => setOpenCreate(false)}
      />
      {selectedService && (
        <>
          <DeleteServiceModal
            id={selectedService.id as string}
            open={openDelete}
            updateServicesAfterDelete={() =>
              onDelete(selectedService.id as string)
            }
            onClose={() => setOpenDelete(false)}
          />
          <EditServiceModal
            open={openEdit}
            onEdit={onEdit}
            serviceInfo={serviceInfo}
            service={selectedService}
            onClose={() => setOpenEdit(false)}
          />
        </>
      )}
    </>
  );
};
