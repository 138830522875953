const choiceAppPath = '/choice';

export const locations = {
  main_page: {
    path: '/',
  },
  choice_app: {
    path: choiceAppPath,
  },
  choice_services: {
    path: `${choiceAppPath}/services-info`,
  },
  choice_blog: {
    path: `${choiceAppPath}/blog`,
  },
  choice_blog_new: {
    path: `${choiceAppPath}/blog/new`,
  },
  choice_blog_edit: {
    path: `${choiceAppPath}/blog/:id`,
  },
  choice_gadgets: {
    path: `${choiceAppPath}/gadgets`,
  },
  choice_models: {
    path: `${choiceAppPath}/models`,
  },
  choice_mails: {
    path: `${choiceAppPath}/mails`,
  },
  choice_pages: {
    path: `${choiceAppPath}/pages`,
  },
  choice_trade_in: {
    path: `${choiceAppPath}/trade-in`,
  },
  choice_business: {
    path: `${choiceAppPath}/choice_business`,
  },
  choice_orders: {
    path: `${choiceAppPath}/orders`,
  },
  choice_book_time: {
    path: `${choiceAppPath}/book-time`,
  },
  choice_recovery_request: {
    path: `${choiceAppPath}/recovery-request`,
  },
};
