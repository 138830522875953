import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BuildIcon from '@mui/icons-material/Build';
import HomeIcon from '@mui/icons-material/Home';
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../components/Breadcrumbs/Breadcrumbs';
import CustomTablePagination from '../../components/CustomTablePagination/CustomTablePagination';
import { DeleteRecoveryRequestModal } from './components/DeleteRecoveryRequestModal';
import { TableRowComponent } from './components/TableRowComponent';
import { Column } from './RecoveryRequestPage.type';
import styles from './styles.module.css';
import { useRecoveryRequestPage } from './useRecoveryRequestPage';

const columns: Column[] = [
  { name: 'status', label: 'Status', minWidth: 100 },
  { name: 'source', label: 'Source', minWidth: 100 },
  { name: 'firstName', label: 'First name', minWidth: 100 },
  { name: 'lastName', label: 'Last name', minWidth: 100 },
  { name: 'email', label: 'Email', minWidth: 100 },
  { name: 'phone', label: 'Phone number', minWidth: 100 },
  { name: 'problemDevices', label: 'Problem devices', minWidth: 400 },
  { name: 'bookTimeDate', label: 'Booked date and time', minWidth: 200 },
  { name: 'orderNumber', label: 'Order number', minWidth: 200 },
  { name: 'address', label: 'Address', minWidth: 200 },
  { name: 'additionalAddressData', label: 'Additional address', minWidth: 200 },
  { name: 'postCode', label: 'Post code', minWidth: 100 },
  { name: 'city', label: 'City', minWidth: 200 },
  { name: 'companyName', label: 'Company name', minWidth: 200 },
  { name: 'companyNIP', label: 'Company NIP', minWidth: 200 },
  { name: 'companyPostCode', label: 'Company post code', minWidth: 200 },
  { name: 'companyCity', label: 'Company city', minWidth: 200 },
  { name: 'companyAddress', label: 'Company address', minWidth: 200 },
  { name: 'lockPasscode', label: 'Lock passcode', minWidth: 100 },
  { name: 'shippingAddress', label: 'Shipping address', minWidth: 200 },
  {
    name: 'shippingAdditionalAddressData',
    label: 'Shipping additional address data',
    minWidth: 200,
  },
  { name: 'shippingPostCode', label: 'Shipping post code', minWidth: 200 },
  { name: 'shippingCity', label: 'Shipping city', minWidth: 200 },
  { name: 'receivingAddress', label: 'Receiving address', minWidth: 200 },
  {
    name: 'receivingAdditionalAddressData',
    label: 'Receiving  additional address',
    minWidth: 200,
  },
  {
    name: 'receivingPostCode',
    label: 'Receiving  post code',
    minWidth: 200,
  },
  {
    name: 'receivingCity',
    label: 'Receiving  city',
    minWidth: 200,
  },
  {
    name: 'createdAt',
    label: 'Created at',
    minWidth: 170,
  },
  {
    name: 'actions',
    label: 'Actions',
    minWidth: 100,
  },
];

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Choice',
    to: '/choice',
    icon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Recovery request',
    to: '',
    icon: <BuildIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

export const RecoveryRequestPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const {
    recoveryRequests,
    metaData,
    page,
    quantity,
    order,
    setPage,
    setQuantity,
    setOrder,
    updateRecoveryRequestAfterDelete,
  } = useRecoveryRequestPage();

  const onRecoveryRequestDelete = (mailId: string) => {
    setOpenModal(true);
    setDeleteId(mailId);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeRecoveryRequest = (
    event: unknown,
    newRecoveryRequest: number,
  ) => {
    setPage(newRecoveryRequest + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setQuantity(+event.target.value);
    setPage(1);
  };

  return (
    <Container maxWidth="xl">
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h2">Recovery request</Typography>
        </div>
        <BreadcrumbsComponent linkList={linkList} />
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.name}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: 'bolder',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {recoveryRequests.map((row, index) => (
                  <TableRowComponent
                    row={row}
                    columns={columns}
                    onRecoveryRequestDelete={onRecoveryRequestDelete}
                    key={index}
                    status={row.status}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {metaData && (
            <CustomTablePagination
              count={metaData.pageCount}
              rowsPerPage={quantity}
              page={page - 1}
              onChange={handleChangeRecoveryRequest}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </div>
      <DeleteRecoveryRequestModal
        open={openModal}
        onClose={onCloseModal}
        recoveryRequestId={deleteId}
        updateRecoveryRequestAfterDelete={updateRecoveryRequestAfterDelete}
      />
    </Container>
  );
};
