import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Card, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { FormEvent, useMemo, useState } from 'react';
import { useAlert } from '../../../../context/Alert.context';
import { parseJsonStringsInObject } from '../../../../helpers/objects';
import { ModelDto } from '../../../../types/models.types';
import {
  BatteryCapacityMoreThenList,
  BatteryCapacityMoreThenPercentsList,
  BatteryList,
  BatteryPercentsList,
  DeviceSetList,
  DeviceSetPercentsList,
  DiagonalList,
  DiagonalPercentsList,
  gadgetTradeInFields,
  IBattery,
  IBatteryCapacityMoreThen,
  IBatteryCapacityMoreThenPercents,
  IBatteryPercents,
  IDeviceSet,
  IDeviceSetPercents,
  IDiagonal,
  IDiagonalPercents,
  IGadgetTradeInFields,
  IIsGlassSurface,
  IIsGlassSurfacePercents,
  IIsScreenFine,
  IIsScreenFinePercents,
  IIsSpecificDamage,
  IIsSpecificDamagePercents,
  IIsWorkFine,
  IIsWorkFinePercents,
  IMemory,
  IMemoryPercents,
  IProcessor,
  IProcessorPercents,
  IScreenFrameState,
  IScreenFrameStatePercents,
  IsGlassSurfaceList,
  IsGlassSurfacePercentsList,
  IsScreenFineList,
  IsScreenFinePercentsList,
  IsSpecificDamageList,
  IsSpecificDamagePercentsList,
  IsWorkFineList,
  IsWorkFinePercentsList,
  ITradeIn,
  ITradeInHardDisk,
  MemoryList,
  MemoryPercentsList,
  ProcessorList,
  ProcessorPercentsList,
  ScreenFrameStateList,
  ScreenFrameStatePercentsList,
  TRADE_FIELDS,
  TradeInHardDiskList,
} from '../../../../types/tradeIn.types';
import { IEditTradeIn } from '../../TradeInPage.type';
import { CardFieldsLayout } from './CardFieldsLayout';
import styles from './ModelModal.module.css';

interface ModelFormProps {
  onSubmit: (tradeIn: IEditTradeIn | any) => void;
  onCancel: () => void;
  defaultValue?: ModelDto;
  loading: boolean;
}

export const splitLabel = (str: string, divider: string) => {
  const list = str.split(divider);
  return list.length > 0 ? list[1] : list[0];
};

export const ModelForm = ({
  onSubmit,
  defaultValue,
  onCancel,
  loading,
}: ModelFormProps) => {
  const { showAlert } = useAlert();
  const [tradeIn, setTradeIn] = useState<ITradeIn>(
    parseJsonStringsInObject(defaultValue?.tradeIn),
  );

  const tradeInFields =
    defaultValue?.gadgetSlug !== undefined
      ? gadgetTradeInFields[
          (defaultValue?.gadgetSlug).toLowerCase() as keyof IGadgetTradeInFields
        ]
      : [];

  const [maxPrice, setMaxPrice] = useState(tradeIn?.maxPrice || '');
  const [minPrice, setMinPrice] = useState(tradeIn?.minPrice || '');
  const [hardDisk, setHardDisk] = useState<ITradeInHardDisk>(
    tradeIn?.hardDisk || {},
  );

  // Memory
  const [memory, setMemory] = useState<IMemory>(tradeIn?.memory || {});
  const [memoryPercents, setMemoryPercents] = useState<IMemoryPercents>(
    tradeIn?.memoryPercents || {},
  );

  // Is work fine
  const [isWorkFine, setIsWorkFine] = useState<IIsWorkFine>(
    tradeIn?.isWorkFine || {},
  );
  const [isWorkFinePercents, setIsWorkFinePercents] =
    useState<IIsWorkFinePercents>(tradeIn?.isWorkFinePercents || {});

  // Is screen fine
  const [isScreenFine, setIsScreenFine] = useState<IIsScreenFine>(
    tradeIn?.isScreenFine || {},
  );
  const [isScreenFinePercents, setIsScreenFinePercents] =
    useState<IIsScreenFinePercents>(tradeIn?.isScreenFinePercents || {});

  // Screen frame state
  const [screenFrameState, setScreenFrameState] = useState<IScreenFrameState>(
    tradeIn?.screenFrameState || {},
  );
  const [screenFrameStatePercents, setScreenFrameStatePercents] =
    useState<IScreenFrameStatePercents>(
      tradeIn?.screenFrameStatePercents || {},
    );

  // Is glass surface
  const [isGlassSurface, setIsGlassSurface] = useState<IIsGlassSurface>(
    tradeIn?.isGlassSurface || {},
  );
  const [isGlassSurfacePercents, setIsGlassSurfacePercents] =
    useState<IIsGlassSurfacePercents>(tradeIn?.isGlassSurfacePercents || {});

  // Is specific damage
  const [isSpecificDamage, setIsSpecificDamage] = useState<IIsSpecificDamage>(
    tradeIn?.isSpecificDamage || {},
  );
  const [isSpecificDamagePercents, setIsSpecificDamagePercents] =
    useState<IIsSpecificDamagePercents>(
      tradeIn?.isSpecificDamagePercents || {},
    );

  // Battery capacity more then
  const [batteryCapacityMoreThen, setBatteryCapacityMoreThen] =
    useState<IBatteryCapacityMoreThen>(tradeIn?.batteryCapacityMoreThen || {});

  const [batteryCapacityMoreThenPercents, setBatteryCapacityMoreThenPercents] =
    useState<IBatteryCapacityMoreThenPercents>(
      tradeIn?.batteryCapacityMoreThenPercents || {},
    );

  // Diagonal
  const [diagonal, setDiagonal] = useState<IDiagonal>(tradeIn?.diagonal || {});

  const [diagonalPercents, setDiagonalPercents] = useState<IDiagonalPercents>(
    tradeIn?.diagonalPercents || {},
  );

  // Processor
  const [processor, setProcessor] = useState<IProcessor>(
    tradeIn?.processor || {},
  );
  const [processorPercents, setProcessorPercents] =
    useState<IProcessorPercents>(tradeIn?.processorPercents || {});

  // Battery
  const [battery, setBattery] = useState<IBattery>(tradeIn?.battery || {});
  const [batteryPercents, setBatteryPercents] = useState<IBatteryPercents>(
    tradeIn?.batteryPercents || {},
  );

  // Device set
  const [deviceSet, setDeviceSet] = useState<IDeviceSet>(
    tradeIn?.deviceSet || {},
  );
  const [deviceSetPercents, setDeviceSetPercents] =
    useState<IDeviceSetPercents>(tradeIn?.deviceSetPercents || {});

  const hardDiskListKeys = Object.keys(hardDisk);

  const memoryListKeys = Object.keys(memory);
  const memoryPercentsListKeys = Object.keys(memoryPercents);

  const isWorkFineListKeys = Object.keys(isWorkFine);
  const isWorkFinePercentsListKeys = Object.keys(isWorkFinePercents);

  const isScreenFineListKeys = Object.keys(isScreenFine);
  const isScreenFinePercentsListKeys = Object.keys(isScreenFinePercents);

  const screenFrameStateListKeys = Object.keys(screenFrameState);
  const screenFrameStatePercentsListKeys = Object.keys(
    screenFrameStatePercents,
  );

  const isGlassSurfaceListKeys = Object.keys(isGlassSurface);
  const isGlassSurfacePercentsListKeys = Object.keys(isGlassSurfacePercents);

  const isSpecificDamageListKeys = Object.keys(isSpecificDamage);
  const isSpecificDamagePercentsListKeys = Object.keys(
    isSpecificDamagePercents,
  );

  const batteryCapacityMoreThenListKeys = Object.keys(batteryCapacityMoreThen);
  const batteryCapacityMoreThenPercentsListKeys = Object.keys(
    batteryCapacityMoreThenPercents,
  );

  const diagonalListKeys = Object.keys(diagonal);
  const diagonalPercentsListKeys = Object.keys(diagonalPercents);

  const processorListKeys = Object.keys(processor);
  const processorPercentsListKeys = Object.keys(processorPercents);

  const batteryListKeys = Object.keys(battery);
  const batteryPercentsListKeys = Object.keys(batteryPercents);

  const deviceSetListKeys = Object.keys(deviceSet);
  const deviceSetPercentsListKeys = Object.keys(deviceSetPercents);

  const handleCreateField = (
    value: any,
    handleState: (value: React.SetStateAction<any>) => void,
    fieldList: any,
  ) => {
    handleState((prevValue: any) => {
      const length = Object.keys(value).length;
      const data = Object.keys(fieldList)[length];
      return data
        ? {
            ...prevValue,
            [data]: '',
          }
        : { ...prevValue };
    });
  };

  const handleOnChangeField = (
    event: React.SyntheticEvent<EventTarget>,
    handleState: (value: React.SetStateAction<any>) => void,
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    handleState((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleRemoveField = (
    key: string,
    value: any,
    handleState: (value: React.SetStateAction<{} | any>) => void,
  ) => {
    const { [key]: _, ...removedData } = value;
    handleState(removedData);
  };

  // Handle Create Button
  const handleCreateIsWorkFineButton = () => {
    handleCreateField(isWorkFine, setIsWorkFine, IsWorkFineList);
    handleCreateField(
      isWorkFinePercents,
      setIsWorkFinePercents,
      IsWorkFinePercentsList,
    );
  };

  const handleCreateIsScreenFineButton = () => {
    handleCreateField(isScreenFine, setIsScreenFine, IsScreenFineList);
    handleCreateField(
      isScreenFinePercents,
      setIsScreenFinePercents,
      IsScreenFinePercentsList,
    );
  };

  const handleCreateScreenFrameStateButton = () => {
    handleCreateField(
      screenFrameState,
      setScreenFrameState,
      ScreenFrameStateList,
    );
    handleCreateField(
      screenFrameStatePercents,
      setScreenFrameStatePercents,
      ScreenFrameStatePercentsList,
    );
  };

  const handleCreateIsGlassSurfaceButton = () => {
    handleCreateField(isGlassSurface, setIsGlassSurface, IsGlassSurfaceList);
    handleCreateField(
      isGlassSurfacePercents,
      setIsGlassSurfacePercents,
      IsGlassSurfacePercentsList,
    );
  };

  const handleCreateIsSpecificDamageButton = () => {
    handleCreateField(
      isSpecificDamage,
      setIsSpecificDamage,
      IsSpecificDamageList,
    );
    handleCreateField(
      isSpecificDamagePercents,
      setIsSpecificDamagePercents,
      IsSpecificDamagePercentsList,
    );
  };

  const handleCreateBatteryCapacityMoreThenButton = () => {
    handleCreateField(
      batteryCapacityMoreThen,
      setBatteryCapacityMoreThen,
      BatteryCapacityMoreThenList,
    );
    handleCreateField(
      batteryCapacityMoreThenPercents,
      setBatteryCapacityMoreThenPercents,
      BatteryCapacityMoreThenPercentsList,
    );
  };

  const handleCreateDiagonalButton = () => {
    handleCreateField(diagonal, setDiagonal, DiagonalList);
    handleCreateField(
      diagonalPercents,
      setDiagonalPercents,
      DiagonalPercentsList,
    );
  };

  const handleCreateMemoryButton = () => {
    handleCreateField(memory, setMemory, MemoryList);
    handleCreateField(memoryPercents, setMemoryPercents, MemoryPercentsList);
  };

  const handleCreateProcessorButton = () => {
    handleCreateField(processor, setProcessor, ProcessorList);
    handleCreateField(
      processorPercents,
      setProcessorPercents,
      ProcessorPercentsList,
    );
  };

  const handleCreateBatteryButton = () => {
    handleCreateField(battery, setBattery, BatteryList);
    handleCreateField(batteryPercents, setBatteryPercents, BatteryPercentsList);
  };

  const handleCreateDeviceSetButton = () => {
    handleCreateField(deviceSet, setDeviceSet, DeviceSetList);
    handleCreateField(
      deviceSetPercents,
      setDeviceSetPercents,
      DeviceSetPercentsList,
    );
  };

  // Handle Change Value
  const handleChangeIsWorkValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setIsWorkFine);
  };

  const handleChangeIsScreenValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setIsScreenFine);
  };

  const handleChangeScreenFrameStateValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setScreenFrameState);
  };

  const handleChangeIsGlassSurfaceValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setIsGlassSurface);
  };

  const handleChangeIsSpecificDamageValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setIsSpecificDamage);
  };

  const handleChangeBatteryCapacityMoreThenValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setBatteryCapacityMoreThen);
  };

  const handleChangeDiagonalValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setDiagonal);
  };

  const handleChangeMemoryValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setMemory);
  };

  const handleChangeProcessorValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setProcessor);
  };

  const handleChangeBatteryValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setBattery);
  };

  const handleChangeDeviceSetValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setDeviceSet);
  };

  // Handle Change Percents Value
  const handleChangeIsWorkPercentsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setIsWorkFinePercents);
  };

  const handleChangeIsScreenPercentsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setIsScreenFinePercents);
  };

  const handleChangeScreenFrameStatePercentsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setScreenFrameStatePercents);
  };

  const handleChangeIsGlassSurfacePercentsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setIsGlassSurfacePercents);
  };

  const handleChangeIsSpecificDamagePercentsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setIsSpecificDamagePercents);
  };

  const handleChangeBatteryCapacityMoreThenPercentsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setBatteryCapacityMoreThenPercents);
  };

  const handleChangeDiagonalPercentsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setDiagonalPercents);
  };

  const handleChangeMemoryPercentsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setMemoryPercents);
  };

  const handleChangeProcessorPercentsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setProcessorPercents);
  };

  const handleChangeBatteryPercentsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setBatteryPercents);
  };

  const handleChangeDeviceSetPercentsValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleOnChangeField(event, setDeviceSetPercents);
  };

  // Handle Remove Field
  const handleRemoveIsWorkFine = (key: string, index: number) => {
    handleRemoveField(
      isWorkFinePercentsListKeys[index],
      isWorkFinePercents,
      setIsWorkFinePercents,
    );
    handleRemoveField(key, isWorkFine, setIsWorkFine);
  };

  const handleRemoveIsScreenFine = (key: string, index: number) => {
    handleRemoveField(
      isScreenFinePercentsListKeys[index],
      isScreenFinePercents,
      setIsScreenFinePercents,
    );
    handleRemoveField(key, isScreenFine, setIsScreenFine);
  };

  const handleRemoveScreenFrameState = (key: string, index: number) => {
    handleRemoveField(
      screenFrameStatePercentsListKeys[index],
      screenFrameStatePercents,
      setScreenFrameStatePercents,
    );
    handleRemoveField(key, screenFrameState, setScreenFrameState);
  };

  const handleRemoveIsGlassSurface = (key: string, index: number) => {
    handleRemoveField(
      isGlassSurfacePercentsListKeys[index],
      isGlassSurfacePercents,
      setIsGlassSurfacePercents,
    );
    handleRemoveField(key, isGlassSurface, setIsGlassSurface);
  };

  const handleRemoveIsSpecificDamage = (key: string, index: number) => {
    handleRemoveField(
      isSpecificDamagePercentsListKeys[index],
      isSpecificDamagePercents,
      setIsSpecificDamagePercents,
    );
    handleRemoveField(key, isSpecificDamage, setIsSpecificDamage);
  };

  const handleRemoveBatteryCapacityMoreThen = (key: string, index: number) => {
    handleRemoveField(
      batteryCapacityMoreThenPercentsListKeys[index],
      batteryCapacityMoreThenPercents,
      setBatteryCapacityMoreThenPercents,
    );
    handleRemoveField(key, batteryCapacityMoreThen, setBatteryCapacityMoreThen);
  };

  const handleRemoveDiagonal = (key: string, index: number) => {
    handleRemoveField(
      diagonalPercentsListKeys[index],
      diagonalPercents,
      setDiagonalPercents,
    );
    handleRemoveField(key, diagonal, setDiagonal);
  };

  const handleRemoveMemory = (key: string, index: number) => {
    handleRemoveField(
      memoryPercentsListKeys[index],
      memoryPercents,
      setMemoryPercents,
    );
    handleRemoveField(key, memory, setMemory);
  };

  const handleRemoveProcessor = (key: string, index: number) => {
    handleRemoveField(
      processorPercentsListKeys[index],
      processorPercents,
      setProcessorPercents,
    );
    handleRemoveField(key, processor, setProcessor);
  };

  const handleRemoveBattery = (key: string, index: number) => {
    handleRemoveField(
      batteryPercentsListKeys[index],
      batteryPercents,
      setBatteryPercents,
    );
    handleRemoveField(key, battery, setBattery);
  };

  const handleRemoveDeviceSet = (key: string, index: number) => {
    handleRemoveField(
      deviceSetPercentsListKeys[index],
      deviceSetPercents,
      setDeviceSetPercents,
    );
    handleRemoveField(key, deviceSet, setDeviceSet);
  };

  const isSubmitBtnDisabled = useMemo(() => {
    return !defaultValue || !maxPrice || !minPrice || !hardDisk;
  }, [maxPrice, minPrice, hardDisk, defaultValue]);

  const onSubmitHdl = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isSubmitBtnDisabled) return;
    onSubmit({
      maxPrice: Number(maxPrice),
      minPrice: Number(minPrice),
      hardDisk: JSON.stringify(hardDisk),
      memory: JSON.stringify(memory),
      memoryPercents: JSON.stringify(memoryPercents),
      isWorkFine: JSON.stringify(isWorkFine),
      isWorkFinePercents: JSON.stringify(isWorkFinePercents),
      isScreenFine: JSON.stringify(isScreenFine),
      isScreenFinePercents: JSON.stringify(isScreenFinePercents),
      screenFrameState: JSON.stringify(screenFrameState),
      screenFrameStatePercents: JSON.stringify(screenFrameStatePercents),
      isGlassSurface: JSON.stringify(isGlassSurface),
      isGlassSurfacePercents: JSON.stringify(isGlassSurfacePercents),
      isSpecificDamage: JSON.stringify(isSpecificDamage),
      isSpecificDamagePercents: JSON.stringify(isSpecificDamagePercents),
      batteryCapacityMoreThen: JSON.stringify(batteryCapacityMoreThen),
      batteryCapacityMoreThenPercents: JSON.stringify(
        batteryCapacityMoreThenPercents,
      ),
      diagonal: JSON.stringify(diagonal),
      diagonalPercents: JSON.stringify(diagonalPercents),
      processor: JSON.stringify(processor),
      processorPercents: JSON.stringify(processorPercents),
      battery: JSON.stringify(battery),
      batteryPercents: JSON.stringify(batteryPercents),
      deviceSet: JSON.stringify(deviceSet),
      deviceSetPercents: JSON.stringify(deviceSetPercents),
    });
  };

  return (
    <form className={styles.form} onSubmit={onSubmitHdl} noValidate>
      <div className={styles.formFieldsWrapper}>
        <div className={styles.formFields}>
          <TextField
            name="maxPrice"
            label="Max price"
            value={maxPrice}
            variant="standard"
            type="number"
            required
            onChange={(e) => setMaxPrice(e.target.value)}
          />
          <TextField
            name="minPrice"
            label="Min price"
            value={minPrice}
            variant="standard"
            type="number"
            required
            onChange={(e) => setMinPrice(e.target.value)}
          />
          <Card className={styles.card}>
            <Typography textAlign={'center'} variant="h5">
              Hard disk
            </Typography>
            <Button
              onClick={() =>
                handleCreateField(hardDisk, setHardDisk, TradeInHardDiskList)
              }
              variant="contained"
            >
              Add Hard disk field
            </Button>
            {hardDiskListKeys.length > 0 &&
              hardDiskListKeys.map((key: string, index: number) => (
                <div className={styles.cardItems} key={index}>
                  <TextField
                    name={key}
                    value={(hardDisk as any)[key] || ''}
                    label={splitLabel(key, 'hardDisk')}
                    variant="standard"
                    required
                    onChange={(event) =>
                      handleOnChangeField(event, setHardDisk)
                    }
                    className={styles.cardInput}
                  />
                  <DeleteIcon
                    color="error"
                    onClick={() =>
                      handleRemoveField(key, hardDisk, setHardDisk)
                    }
                    className={styles.deleteButton}
                  />
                </div>
              ))}
          </Card>
          {tradeInFields.length > 0 &&
            tradeInFields.map((key: string, index: number) => {
              return (
                <>
                  {key === TRADE_FIELDS.IS_WORK_FINE && (
                    <>
                      <CardFieldsLayout
                        key={index}
                        headerText="Is work fine"
                        buttonText="Add is work fine field"
                        handleCreateButton={handleCreateIsWorkFineButton}
                        listKeys={isWorkFineListKeys}
                        value={isWorkFine}
                        label={'isWorkFine'}
                        handleChangeValue={handleChangeIsWorkValue}
                        percentsListKeys={isWorkFinePercentsListKeys}
                        valuePercents={isWorkFinePercents}
                        labelPercents={'isWorkFine'}
                        handleChangePercentsValue={
                          handleChangeIsWorkPercentsValue
                        }
                        handleRemoveButton={handleRemoveIsWorkFine}
                      />
                    </>
                  )}
                  {key === TRADE_FIELDS.IS_SCREEN_FINE && (
                    <>
                      <CardFieldsLayout
                        key={index}
                        headerText="Is screen fine"
                        buttonText="Add is screen fine field"
                        handleCreateButton={handleCreateIsScreenFineButton}
                        listKeys={isScreenFineListKeys}
                        value={isScreenFine}
                        label={'isScreenFine'}
                        handleChangeValue={handleChangeIsScreenValue}
                        percentsListKeys={isScreenFinePercentsListKeys}
                        valuePercents={isScreenFinePercents}
                        labelPercents={'isScreenFine'}
                        handleChangePercentsValue={
                          handleChangeIsScreenPercentsValue
                        }
                        handleRemoveButton={handleRemoveIsScreenFine}
                      />
                    </>
                  )}
                  {key === TRADE_FIELDS.SCREEN_FRAME_STATE && (
                    <CardFieldsLayout
                      key={index}
                      headerText="Screen and frame state"
                      buttonText="Add screen frame state field"
                      handleCreateButton={handleCreateScreenFrameStateButton}
                      listKeys={screenFrameStateListKeys}
                      value={screenFrameState}
                      label={'screenFrameState'}
                      handleChangeValue={handleChangeScreenFrameStateValue}
                      percentsListKeys={screenFrameStatePercentsListKeys}
                      valuePercents={screenFrameStatePercents}
                      labelPercents={'screenFrameState'}
                      handleChangePercentsValue={
                        handleChangeScreenFrameStatePercentsValue
                      }
                      handleRemoveButton={handleRemoveScreenFrameState}
                    />
                  )}
                  {key === TRADE_FIELDS.IS_GLASS_SURFACE && (
                    <CardFieldsLayout
                      key={index}
                      headerText="Is glass and surface"
                      buttonText="Add is glass surface field"
                      handleCreateButton={handleCreateIsGlassSurfaceButton}
                      listKeys={isGlassSurfaceListKeys}
                      value={isGlassSurface}
                      label={'isGlassSurface'}
                      handleChangeValue={handleChangeIsGlassSurfaceValue}
                      percentsListKeys={isGlassSurfacePercentsListKeys}
                      valuePercents={isGlassSurfacePercents}
                      labelPercents={'isGlassSurface'}
                      handleChangePercentsValue={
                        handleChangeIsGlassSurfacePercentsValue
                      }
                      handleRemoveButton={handleRemoveIsGlassSurface}
                    />
                  )}
                  {key === TRADE_FIELDS.IS_SPECIFIC_DAMAGE && (
                    <CardFieldsLayout
                      key={index}
                      headerText="Is specific damage"
                      buttonText="Add is specific damage field"
                      handleCreateButton={handleCreateIsSpecificDamageButton}
                      listKeys={isSpecificDamageListKeys}
                      value={isSpecificDamage}
                      label={'isSpecificDamage'}
                      handleChangeValue={handleChangeIsSpecificDamageValue}
                      percentsListKeys={isSpecificDamagePercentsListKeys}
                      valuePercents={isSpecificDamagePercents}
                      labelPercents={'isSpecificDamage'}
                      handleChangePercentsValue={
                        handleChangeIsSpecificDamagePercentsValue
                      }
                      handleRemoveButton={handleRemoveIsSpecificDamage}
                    />
                  )}
                  {key === TRADE_FIELDS.BATTERY_CAPACITY_MORE_THEN && (
                    <CardFieldsLayout
                      key={index}
                      headerText="Battery capacity more then 85%"
                      buttonText="Add battery capacity more then 85% field"
                      handleCreateButton={
                        handleCreateBatteryCapacityMoreThenButton
                      }
                      listKeys={batteryCapacityMoreThenListKeys}
                      value={batteryCapacityMoreThen}
                      label={'batteryCapacityMoreThen'}
                      handleChangeValue={
                        handleChangeBatteryCapacityMoreThenValue
                      }
                      percentsListKeys={batteryCapacityMoreThenPercentsListKeys}
                      valuePercents={batteryCapacityMoreThenPercents}
                      labelPercents={'batteryCapacityMoreThen'}
                      handleChangePercentsValue={
                        handleChangeBatteryCapacityMoreThenPercentsValue
                      }
                      handleRemoveButton={handleRemoveBatteryCapacityMoreThen}
                    />
                  )}
                  {key === TRADE_FIELDS.DIAGONAL && (
                    <CardFieldsLayout
                      key={index}
                      headerText="Diagonal"
                      buttonText="Add diagonal field"
                      handleCreateButton={handleCreateDiagonalButton}
                      listKeys={diagonalListKeys}
                      value={diagonal}
                      label={'diagonal'}
                      handleChangeValue={handleChangeDiagonalValue}
                      percentsListKeys={diagonalPercentsListKeys}
                      valuePercents={diagonalPercents}
                      labelPercents={'diagonal'}
                      handleChangePercentsValue={
                        handleChangeDiagonalPercentsValue
                      }
                      handleRemoveButton={handleRemoveDiagonal}
                    />
                  )}
                  {key === TRADE_FIELDS.MEMORY && (
                    <CardFieldsLayout
                      key={index}
                      headerText="Memory"
                      buttonText="Add memory field"
                      handleCreateButton={handleCreateMemoryButton}
                      listKeys={memoryListKeys}
                      value={memory}
                      label={'memory'}
                      handleChangeValue={handleChangeMemoryValue}
                      percentsListKeys={memoryPercentsListKeys}
                      valuePercents={memoryPercents}
                      labelPercents={'memory'}
                      handleChangePercentsValue={
                        handleChangeMemoryPercentsValue
                      }
                      handleRemoveButton={handleRemoveMemory}
                    />
                  )}
                  {key === TRADE_FIELDS.PROCESSOR && (
                    <CardFieldsLayout
                      key={index}
                      headerText="Processor"
                      buttonText="Add processor field"
                      handleCreateButton={handleCreateProcessorButton}
                      listKeys={processorListKeys}
                      value={processor}
                      label={'processor'}
                      handleChangeValue={handleChangeProcessorValue}
                      percentsListKeys={processorPercentsListKeys}
                      valuePercents={processorPercents}
                      labelPercents={'processor'}
                      handleChangePercentsValue={
                        handleChangeProcessorPercentsValue
                      }
                      handleRemoveButton={handleRemoveProcessor}
                    />
                  )}
                  {key === TRADE_FIELDS.BATTERY && (
                    <CardFieldsLayout
                      key={index}
                      headerText="Battery"
                      buttonText="Add battery field"
                      handleCreateButton={handleCreateBatteryButton}
                      listKeys={batteryListKeys}
                      value={battery}
                      label={'battery'}
                      handleChangeValue={handleChangeBatteryValue}
                      percentsListKeys={batteryPercentsListKeys}
                      valuePercents={batteryPercents}
                      labelPercents={'battery'}
                      handleChangePercentsValue={
                        handleChangeBatteryPercentsValue
                      }
                      handleRemoveButton={handleRemoveBattery}
                    />
                  )}
                  {key === TRADE_FIELDS.DEVICE_SET && (
                    <CardFieldsLayout
                      key={index}
                      headerText="Device set"
                      buttonText="Add device set field"
                      handleCreateButton={handleCreateDeviceSetButton}
                      listKeys={deviceSetListKeys}
                      value={deviceSet}
                      label={'deviceSet'}
                      handleChangeValue={handleChangeDeviceSetValue}
                      percentsListKeys={deviceSetPercentsListKeys}
                      valuePercents={deviceSetPercents}
                      labelPercents={'deviceSet'}
                      handleChangePercentsValue={
                        handleChangeDeviceSetPercentsValue
                      }
                      handleRemoveButton={handleRemoveDeviceSet}
                    />
                  )}
                </>
              );
            })}
        </div>
      </div>
      <DialogActions>
        <Button disabled={loading} variant="outlined" onClick={onCancel}>
          CANCEL
        </Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          disabled={isSubmitBtnDisabled}
        >
          SAVE
        </LoadingButton>
      </DialogActions>
    </form>
  );
};
