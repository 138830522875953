import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeIcon from '@mui/icons-material/Home';
import { Button, Container, Typography } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { API } from '../../api/api';
import { URLS } from '../../api/urls';
import {
  BreadcrumbsComponent,
  LinkItem,
} from '../../components/Breadcrumbs/Breadcrumbs';
import { Modal } from '../../components/Modal/Modal';
import { transformReservedTime } from '../../helpers/timeTransform';
import styles from './styes.module.css';

type ReservationResponse = {
  id: string;
  date: string;
  time: string;
  status: string;
};

const linkList: LinkItem[] = [
  {
    name: 'Projects',
    to: '/',
    icon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Choice',
    to: '/choice',
    icon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
  {
    name: 'Book time',
    to: '',
    icon: <CalendarMonthIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  },
];

const BookTimePage = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [includedTimes, setIncludedTimes] = useState<Date[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [timeText, setTimeText] = useState('');

  useEffect(() => {
    API.get<ReservationResponse[]>(`${URLS.RESERVATIONS}`).then(({ data }) => {
      const include = data.map((res) => {
        const [hours, minutes] = res.time.split(':').map(Number);
        return new Date(new Date(res.date).setHours(hours, minutes, 0));
      });

      setIncludedTimes(include);
    });
  }, []);

  const filterIncludedTimes = () => {
    if (!startDate) return [];
    const selectedDate = startDate.toISOString().split('T')[0];
    return includedTimes.filter(
      (time: Date) => time.toISOString().split('T')[0] === selectedDate,
    );
  };

  const handleChange = (date: Date | null) => {
    if (date) {
      const isIncludedTime = includedTimes.some(
        (time) => time.toISOString() === date.toISOString(),
      );
      if (isIncludedTime) {
        setSelectedTime(date);
        setOpen(true);
        setTimeText(date.toString());
      } else {
        setStartDate(date);
      }
    }
  };
  const handleDisableTime = () => {
    if (selectedTime) {
      const formattedDate = selectedTime.toISOString();
      const reservedTime = transformReservedTime(formattedDate);
      API.put(`${URLS.RESERVATIONS}/disable`, reservedTime)
        .then(() => {
          setIncludedTimes((prev) =>
            prev.filter((time) => time.toISOString() !== formattedDate),
          );
          setOpen(false);
          setSelectedTime(null);
        })
        .catch((error) => {
          console.error('Failed to disable time:', error);
        });
    }
  };

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }: any, ref: any) => (
      <button className={className} onClick={onClick} ref={ref}>
        {value}
      </button>
    ),
  );

  return (
    <Container className={styles.bookTimeContainer} maxWidth="xl">
      <Typography variant="h4">Date and time:</Typography>
      <BreadcrumbsComponent linkList={linkList} />
      <div className={styles.datePickerExampleWrapper}>
        <div className={styles.inputWrapper}>
          <DatePicker
            selected={startDate}
            onChange={handleChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="time"
            dateFormat="MMMM d, yyyy HH:mm"
            className={styles.datePicker}
            placeholderText="Select date and time"
            popperClassName={styles.customPopper}
            calendarClassName={styles.datePickerCalendar}
            popperPlacement="bottom-start"
            shouldCloseOnSelect={false}
            wrapperClassName={styles.wrapperDatePicker}
            showTimeCaption={false}
            minTime={new Date(new Date().setHours(9, 0, 0))}
            maxTime={new Date(new Date().setHours(18, 30, 0))}
            customInput={
              <ExampleCustomInput className="datepicker-custom-input" />
            }
            includeTimes={filterIncludedTimes()}
          />
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Disable booked time"
      >
        <Typography variant="body1">
          Are you sure you want to disable <strong>{timeText}</strong> time?
        </Typography>
        <div className={styles.modalActions}>
          <Button onClick={() => setOpen(false)} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleDisableTime} variant="contained" color="error">
            Disable Time
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default BookTimePage;
