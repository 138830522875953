import { IPaginationMetaDtoParameters } from '../../../types/types';
import { IRecoveryRequest } from '../RecoveryRequestPage.type';

export interface IInitialState {
  recoveryRequests: IRecoveryRequest[];
  metaData: IPaginationMetaDtoParameters | null;
  page: number;
  quantity: number;
  order: string;
}

export const actionRecoveryRequestTypes = {
  SET_RECOVERY_REQUESTS: 'SET_RECOVERY_REQUESTS' as const,
  SET_META_DATA: 'SET_META_DATA' as const,
  SET_PAGE: 'SET_PAGE' as const,
  SET_QUANTITY: 'SET_QUANTITY' as const,
  SET_ORDER: 'SET_ORDER' as const,
};

export type ActionTypes =
  | {
      type: typeof actionRecoveryRequestTypes.SET_RECOVERY_REQUESTS;
      payload: IRecoveryRequest[];
    }
  | {
      type: typeof actionRecoveryRequestTypes.SET_META_DATA;
      payload: IPaginationMetaDtoParameters;
    }
  | {
      type: typeof actionRecoveryRequestTypes.SET_PAGE;
      payload: number;
    }
  | {
      type: typeof actionRecoveryRequestTypes.SET_QUANTITY;
      payload: number;
    }
  | {
      type: typeof actionRecoveryRequestTypes.SET_ORDER;
      payload: string;
    };
