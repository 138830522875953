import { IPaginationMetaDtoParameters } from '../../../types/types';
import { IOrder } from '../OrdersPage.type';

export interface IInitialState {
  orders: IOrder[];
  metaData: IPaginationMetaDtoParameters | null;
  page: number;
  quantity: number;
  order: string;
}

export const actionOrdersTypes = {
  SET_ORDERS: 'SET_ORDERS' as const,
  SET_META_DATA: 'SET_META_DATA' as const,
  SET_PAGE: 'SET_PAGE' as const,
  SET_QUANTITY: 'SET_QUANTITY' as const,
  SET_ORDER: 'SET_ORDER' as const,
};

export type ActionTypes =
  | {
      type: typeof actionOrdersTypes.SET_ORDERS;
      payload: IOrder[];
    }
  | {
      type: typeof actionOrdersTypes.SET_META_DATA;
      payload: IPaginationMetaDtoParameters;
    }
  | {
      type: typeof actionOrdersTypes.SET_PAGE;
      payload: number;
    }
  | {
      type: typeof actionOrdersTypes.SET_QUANTITY;
      payload: number;
    }
  | {
      type: typeof actionOrdersTypes.SET_ORDER;
      payload: string;
    };
